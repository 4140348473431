import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { onServerPrefetch } from 'vue'
import type { ApplicationsData } from '../types/Applications'

export const USE_APPS_TANSTACK_KEY = ['applications']

export const useApps = (
  options?: UseQueryOptions<ApplicationsData, unknown>
) => {
  const queryClient = useQueryClient()
  const headers = useRequestHeaders(['cookie'])
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()

  const queryFn = async () => {
    const rawAppsData = await $customFetch<ApplicationsData>('/api/apps', {
      ...headers,
      query: {
        lang: locale.value
      }
    })

    if (!rawAppsData) return null

    return rawAppsData
  }

  const { suspense, ...rest } = useQuery(USE_APPS_TANSTACK_KEY, queryFn, {
    refetchOnWindowFocus: false,
    ...options
  })

  const prefetch = async () => {
    await queryClient.prefetchQuery({
      queryKey: USE_APPS_TANSTACK_KEY,
      queryFn
    })
  }

  onServerPrefetch(async () => {
    if (options?.enabled === false) return
    await suspense()
  })

  return { suspense, prefetch, ...rest }
}
